import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import { JSONSchema7 } from 'json-schema'
import { appContext } from "./useApp"
import { Button, Flex } from "antd"
import { datapointContext } from "./renderers"
import { DeleteOutlined, HighlightOutlined, PlusOutlined } from "@ant-design/icons"
import { RangeSelection } from "./useRangeSelection"


const useFormSchema = (value: any, schema: JSONSchema7) => {
    const { control, register, handleSubmit, getValues, setValue, formState, watch, reset } = useForm<any>({ defaultValues: value })
    return { control, register, handleSubmit, schema, data: value, value, setValue, getValues, formState, watch, reset }
}
type UseFormSchema = ReturnType<typeof useFormSchema>
export const formContext = createContext(undefined as unknown as UseFormSchema)



export const DynamicForm = (props: { children: React.ReactNode }) => {
    const ctx = useContext(datapointContext)
    const { value, schema } = ctx
    const formSchema = useFormSchema(value, schema)

    formSchema.watch((data) => {
        ctx.onChange(data)
    })

    // be informed of any changes to the form
    const dirty = formSchema.formState.isDirty && Object.keys(formSchema.formState.dirtyFields).length > 0
    const onDirty = ctx.onDirty
    useEffect(() => {
        onDirty?.(dirty)
    }, [dirty, onDirty])

    const { canceling } = ctx
    useEffect(() => {
        if (canceling) {
            formSchema.reset()
        }
    }, [canceling, formSchema])


    return (<formContext.Provider value={formSchema}>
        <form onSubmit={formSchema.handleSubmit(ctx.onSubmit)} className="dynamic-form">
            {props.children}
        </form>
    </formContext.Provider>)
}

// path is a (virtual) path in the json object
// e.g. items.0.name 
// 0 indicates an array property
const schemaFromPath = (schema: JSONSchema7, path: string) => {
    // figure the prop given the json path
    const parts = path.length > 0 ? path.split(".") : []
    const schemaParts = parts.flatMap(p => {
        const isNumber = !isNaN(parseInt(p))
        if (!isNumber) return ["properties", p]
        return ["items"]
    })
    let current = schema as any
    for (const part of schemaParts) {
        if (!current) {
            return undefined
        }
        current = current[part]
    }
    return current
}


export const FormItemArray = (props: { path: string, renderItem: (index: number) => React.ReactNode, label?: string }) => {
    const { schema, control } = useContext(formContext)
    const { path } = props
    // expect schema to be an array schema
    const childSchema = schemaFromPath(schema, props.path)
    if (childSchema.type !== "array") {
        throw new Error("schema is not an array schema")
    }

    // note: 'name' will likely fail if we have nested arrays; 
    // in that case we should name as such: const { fields } = useFieldArray({ name: `test.${index}.keyValue` as 'test.0.keyValue' });

    const formName = path.length > 0 ? `${path}` : "0"
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: formName, // unique name for your Field Array
    });

    return (<div className="form-item-array">
        {props.label && <label>{props.label}:</label>}
        <ul>
            {fields.map((field, index) => (
                <li key={field.id}>
                    <Located path={`${path}.${index}`}>
                        <div key={field.id} className="form-item-array-element">
                            {props.renderItem(index)}
                            {fields.length > 1 && <Button onClick={() => remove(index)} icon={<DeleteOutlined style={{ fontSize: 12 }} />} />}
                            {index === fields.length - 1 && <Button onClick={() => append({})} icon={<PlusOutlined style={{ fontSize: 12 }} />} />}
                        </div>
                    </Located>
                </li>
            ))}

        </ul>
    </div>)
}

// function to convert camelCase to words
const camelCaseToWords = (inputString: string) => {
    return inputString.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
}



// path is a (virtual) path in the json object
// e.g. items.0.name 
// 0 indicates an array property
export const FormItem = (props: { path: string, label?: string, hideLabel?: boolean, className?: string, date?: boolean }) => {
    const { register, schema, control } = useContext(formContext)
    const childSchema = schemaFromPath(schema, props.path)
    if (!childSchema) {
        return (<div className="form-error">{`field '${props.path}' not found in schema`}</div>)
    }
    const className = props.className || "form-item"

    const type = childSchema.type
    const lastPathPart = props.path.split(".").at(-1)
    const label = camelCaseToWords(props.label || lastPathPart || "")

    const enumValues = childSchema.enum
    if (enumValues && enumValues.length > 0) {
        const options = enumValues.map((v: any) => (<option key={v} value={v}>{v}</option>))
        const oneUp = schemaFromPath(schema, props.path.split(".").slice(0, -1).join("."))
        const isRequired = oneUp?.required?.includes(props.path.split(".").at(-1))
        const additional = {} as Record<string, any>
        if (!isRequired) {
            options.push(<option key="empty" value={""}></option>)
            additional.defaultValue = ""
        }

        return (<div className={className}>
            {!props.hideLabel && <label>{label}</label>}
            <Controller {...additional} control={control} name={props.path} render={({ field }) => (
                <select {...field} style={{ maxWidth: "200px", textOverflow: "ellipsis" }}>{options}</select>
            )} />
        </div>)
    }

    if (type === "string") {
        return (<div className={className}>
            {!props.hideLabel && <label>{label}</label>}
            <input {...register(props.path)} />
        </div>)
    } else if (type === "number") {
        if (props.date) {
            return (<div className={className}>
                {!props.hideLabel && <label>{label}</label>}
                <input type="number" {...register(props.path)} />
            </div>)
        }
        return (<div className={className}>
            {!props.hideLabel && <label>{label}</label>}
            {/* special handling for numbers, to handle commas */}
            <Controller
                control={control}
                name={props.path}
                render={({ field: { onChange, value, ...field } }) => (
                    <input
                        type="text"
                        {...field}
                        value={value?.toLocaleString('en-US') || ''}
                        onChange={(e) => {
                            const rawValue = e.target.value.replace(/,/g, '');
                            const numberValue = parseFloat(rawValue);
                            onChange(!isNaN(numberValue) ? numberValue : '');
                        }}
                        style={{ width: "88px" }}
                    />
                )}
            />
        </div>)
    } else if (type === "boolean") {
        return (<div className={className}>
            {!props.hideLabel && <label>{label}</label>}
            <input type="checkbox" {...register(props.path)} />
        </div>)
    } else {
        return (<div className={className}>
            {!props.hideLabel && <label>{label}</label>}
            {/* <input type="object" {...register(props.path)} /> */}
        </div>)
    }
}

type LocatedType = {
    referenceText: string,
    location?: {
        startLocator: string,
        endLocator?: string
    }
}

const EditingControl = (props: { path: string, onEdit: (editing: boolean) => void }) => {
    const { onEdit } = props
    const [editingReferenceText, setEditingReferenceText] = useState(false)

    const { onSubmit } = useContext(datapointContext)

    const context = useContext(appContext)
    const { setFocus, setHover, setHighlights, setReferenceTextEditCallback, setSelectionEnabled } = context

    const { data, setValue, register, handleSubmit } = useContext(formContext)
    let current = data
    if (props.path.length > 0) {
        for (const part of props.path.split(".")) {
            current = current[part]
        }
    }
    const located = current as LocatedType
    const hasLocation = located && located.location && located.location.startLocator && located.location.endLocator

    useEffect(() => {
        if (hasLocation) {
            setHighlights((existing) => [...existing, { startLocator: located.location!.startLocator!, endLocator: located.location!.endLocator! }])
        }
    }, [hasLocation, located, setHighlights])

    useEffect(() => {
        setSelectionEnabled(editingReferenceText)
    }, [editingReferenceText, setSelectionEnabled])

    useEffect(() => {
        onEdit(editingReferenceText)
    }, [editingReferenceText, onEdit])

    const toggleEditing = useCallback(() => {
        if (!editingReferenceText) {
            setEditingReferenceText(true)
            setReferenceTextEditCallback(() => (action: string, selection?: RangeSelection) => {
                if (action === "APPLY_SELECTION" && selection && selection.text.length > 0) {
                    setValue("referenceText", selection.text)
                    setValue("location.startLocator", selection.startLocator)
                    setValue("location.endLocator", selection.endLocator)
                    setValue("location.startWord", selection.startWord)
                    setValue("location.endWord", selection.endWord)
                    handleSubmit(onSubmit)()
                    setEditingReferenceText(false)

                    // remove previous highlits
                    setHighlights((existing) =>
                        existing.filter(h => h.startLocator !== located.location!.startLocator! && h.endLocator !== located.location!.endLocator!)
                    )

                    selection.startLocator && setFocus({ locator: selection.startLocator })
                    setHover([{ startLocator: selection.startLocator, endLocator: selection.endLocator }])

                }
                if (action === "CANCEL_SELECTION") {
                    setEditingReferenceText(false)
                }
            })
        } else {
            setEditingReferenceText(false)
            setReferenceTextEditCallback(undefined)
        }

    }, [editingReferenceText, handleSubmit, located.location, onSubmit, setFocus, setHighlights, setHover, setReferenceTextEditCallback, setValue])


    const prefix = props.path.length > 0 ? `${props.path}.` : ""

    const referenceTextPath = `${prefix}referenceText`
    const startLocatorPath = `${prefix}location.startLocator`
    const endLocatorPath = `${prefix}location.endLocator`
    const startWordPath = `${prefix}location.startWord`
    const endWordPath = `${prefix}location.endWord`
    register(referenceTextPath, { value: data.referenceText })
    register(startLocatorPath, { value: data.location?.startLocator })
    register(endLocatorPath, { value: data.location?.endLocator })
    register(startWordPath, { value: data.location?.startWord })
    register(endWordPath, { value: data.location?.endWord })

    return <Button icon={<HighlightOutlined style={editingReferenceText ? { backgroundColor: "yellow" } : {}} />} onClick={toggleEditing} />
}

export const Located = (
    props: {
        path: string,
        children?: React.ReactNode
    }) => {

    const [editingReferenceText, setEditingReferenceText] = useState(false)
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)

    const context = useContext(appContext)
    const { setFocus, setHover, setHighlights, setReferenceText } = context

    const { data } = useContext(formContext)
    let current = data
    if (props.path.length > 0) {
        for (const part of props.path.split(".")) {
            current = current[part]
        }
    }
    const located = current as LocatedType
    const hasLocation = located && located.location && located.location.startLocator && located.location.endLocator

    useEffect(() => {
        if (hasLocation) {
            setHighlights((existing) => [...existing, { startLocator: located.location!.startLocator!, endLocator: located.location!.endLocator! }])
        }
    }, [hasLocation, located, setHighlights])

    if (!hasLocation) {
        return <div style={{ padding: 6 }}>{props.children}</div>
    }

    return <div className={`hoverableCell ${editingReferenceText ? "editing" : ""}`} style={{ padding: 6 }}
        onMouseEnter={() => {
            located.referenceText && setReferenceText(located.referenceText)
            located.location && setHover([{ startLocator: located.location.startLocator, endLocator: located.location.endLocator }])
            setTimer(setTimeout(() => {
                located.location?.startLocator && setFocus({ locator: located.location?.startLocator });
            }, 500))
        }}
        onMouseLeave={() => {
            if (timer) {
                clearTimeout(timer)
                setTimer(undefined)
            }
        }}
        onClick={() => {
            located.location?.startLocator && setFocus({ locator: located.location?.startLocator });
        }}
    >

        <div className={`form-item-array-element`}>
            <Flex gap={8} align="flex-end" justify="space-between">
                {props.children}
                <EditingControl path={props.path} onEdit={setEditingReferenceText} />
            </Flex>

        </div>
    </div>
}