import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";

import { appContext } from "./useApp";
import { firstLocation, reviewsContext, UseReviews, valueOf } from "./useReviews";
import { CheckOutlined, CloseOutlined, DownOutlined, HighlightOutlined, LoadingOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { Review } from "../../api-clients/def14a";
import Button from "antd/es/button/button";

import { Breadcrumb, Col, Dropdown, Empty, Flex, Row, Space, Switch } from "antd";
import { DynamicCard, hasTableView } from "./renderers";
import { breadCrumb } from "./Minimap";
import { viewSelectorContext } from "./useViewSelector";
import { RangeSelection } from "./useRangeSelection";


export const ReviewPanel = () => {
    const context = useContext(appContext)
    const ctx = useContext(reviewsContext)

    const { navLocator, reviewFilter, data } = ctx
    const { setFocus, setHighlights } = context

    useEffect(() => {
        setHighlights([])
    }, [navLocator, reviewFilter, setHighlights])

    // highlight the first datapoint
    useEffect(() => {
        setFocus((focused) => {
            const inScope = focused && data?.some(r => firstLocation(valueOf(r))?.startLocator === focused.locator)
            if (inScope) return focused
            const loc = data && data.length > 0 
                ? firstLocation(valueOf(data[0])) 
                : undefined
            return loc
                ? { locator: loc.startLocator }
                : undefined
        })
    }, [data, setFocus])


    return (
        <div style={{ padding: 12, height: "100%" }}>
            <Situation {...ctx} />
            <ScrollArea.Root style={{ height: `calc(100% - 250px)` }}>
                <ScrollArea.Viewport style={{ height: "100%" }}>
                    <DataPointsPanel />
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                    className="ScrollAreaScrollbar"
                    orientation="vertical"
                >
                    <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
            </ScrollArea.Root>
        </div>
    )
}

const DataPointsPanel = () => {
    const { accept, reject, correct, data, ready, reviewFilter } = useContext(reviewsContext)
    const [message, setMessage] = useState<string | undefined>()

    const isEmpty = data?.length === 0

    return ready ? (<>
        {isEmpty && <Empty description={`No reviews in ${reviewFilter}`} />}
        <Flex dir="vertical" gap={4} vertical style={{ paddingTop: 12 }} >
            <CreateControl onCreated={(numExtracted)=> setMessage(`${numExtracted} items were found in the extract`)} />
            <CreateFeedback message={message} />
            {data?.map(r => <ReviewCard key={r.id} review={r} accept={accept} reject={reject} correct={correct} />)}
        </Flex>
    </>
    ) : <LoadingOutlined style={{ fontSize: 24, padding: 24 }} />
}

const CreateControl = (props: {onCreated: (numExtracted: number | undefined) => void}) => {
    const { onCreated } = props
    const { navLocator, extractFromFragment, extracting} = useContext(reviewsContext)
    const { setSelectionEnabled, setReferenceTextEditCallback } = useContext(appContext)

    const [creating, setCreating] = useState(false)

    useEffect(() => {
        setSelectionEnabled(creating)
    }, [creating, setSelectionEnabled, setReferenceTextEditCallback])   


    const addCallback = useCallback(async (action: string, selection: RangeSelection | undefined) => {
        if (action === "APPLY_SELECTION") {
            if (!selection?.text) return
            setCreating(false)
            const numExtracted = await extractFromFragment(navLocator, selection)
            onCreated(numExtracted)
        } else if (action === "CANCEL_SELECTION") {
            setCreating(false)
        }
    },
    [extractFromFragment, navLocator, onCreated])

    const onClick = useCallback(() => {
        setCreating((currentlyCreating) => {
            if (currentlyCreating) {
                setReferenceTextEditCallback(undefined)
            } else {
                setReferenceTextEditCallback(()=>addCallback)
            }
            return !currentlyCreating
        })
    }, [setCreating, setReferenceTextEditCallback, addCallback])

    return (<>
            <Button
            icon={<PlusOutlined />} 
            disabled={extracting}
            onClick={onClick}>
                {extracting 
                    ? <Flex gap={6} align="center"><div>processing extract, this may take a minute</div>    <LoadingOutlined  /></Flex>
                    : creating 
                        ? <Flex gap={6}><div>select text to create entries</div><HighlightOutlined style={{ fontSize: 12, backgroundColor: "yellow" }} /></Flex>
                        : <Flex gap={6}><div>create entries</div><HighlightOutlined style={{ fontSize: 12}} /></Flex>
                }                                                            
            </Button>
    </>)
}

const CreateFeedback = (props: { message: string | undefined })=>{
    const { message } = props
    const [showMessage, setShowMessage] = useState<string | undefined>()
    useEffect(() => {
        if (message && message.length > 0){
            setShowMessage(message)
            setTimeout(() => {
                setShowMessage(undefined)
            }, 5000)
        }
    }, [setShowMessage, message])

    return <div>&nbsp;{showMessage && showMessage}</div>
}

const ReviewCard = (
    props: {
        review: Review | undefined,
        accept: (review: Review) => Promise<void>,
        reject: (review: Review) => Promise<void>,
        correct: (review: Review) => Promise<void>,
    }
) => {
    const { accept, reject, correct, review } = props
    const [editing, setEditing] = useState(false)
    const [canceling, setCanceling] = useState(false)
    const [formValue, setFormValue] = useState(undefined)
    
    useEffect(() => {
        setEditing(false)
        setCanceling(false)
        setFormValue(undefined)
    }, [review])

    const doAccept = useCallback(() => {
        if (!review) return
        if (editing) {
            const newValue = { ...review, result: formValue }
            setEditing(false)
            correct(newValue)
        } else {
            accept(review)
        }
    }, [review, editing, formValue, accept, correct])

    const doSubmit = useCallback((val: any) => {
        if (!review || !val) return
        const newValue = { ...review, result: val }
        correct(newValue)
        setEditing(false)
        setCanceling(false)
        setFormValue(undefined)
    }, [review, correct])

    const doReject = useCallback(() => {
        if (!review) return
        setEditing(false)
        setFormValue(undefined)
        setCanceling(false)
        reject(review)
    }, [review, reject])

    if (!review) return null
    return (
        <div style={{ padding: 6, backgroundColor: "white", borderRadius: 4, marginRight: 12 }}>
            <Flex gap={4}>
                <div style={{ width: 100, minWidth: 100 }}>
                    <Subject subject={review.datapointCoordinates as { name?: string, role?: string }} />
                </div>
                <div style={{ width: "100%" }}>
                    <DynamicCard
                        review={review}
                        onSubmit={val => doSubmit(val)}
                        onDirty={(val) => {
                            setEditing(val)
                            if (!val) {
                                setCanceling(false)
                                setFormValue(undefined)
                            }
                        }}
                        onChange={(val) => setFormValue(val)}
                        canceling={canceling} />
                </div>
                {/* <span style={{ fontSize: 10 }}>{review.assessment}</span> */}
                <Flex gap={12} justify="flex-end" style={{ paddingRight: 12, marginTop: 16 }}>
                    {editing && <Button icon={<StopOutlined style={{ fontSize: 10 }} />} onClick={() => setCanceling(true)} style={{ height: 24, width: 24, }} className={"review-cancel " + review.assessment || ""} />}
                    <Button icon={<CheckOutlined style={{ fontSize: 10 }} />} onClick={() => doAccept()} style={{ height: 24, width: 24, }} className={"review-accept " + review.assessment || ""} />
                    <Button icon={<CloseOutlined style={{ fontSize: 10 }} />} onClick={() => doReject()} style={{ height: 24, width: 24, }} className={"review-reject " + review.assessment || ""} />
                </Flex>
            </Flex>
        </div>
    )
}



const Situation = (props: UseReviews) => {
    const { situationStats, setReviewFilter } = props
    const subsection = situationStats.at(1)?.namespace ?? ""
    const viewContext = useContext(viewSelectorContext)

    const crumbs = breadCrumb(situationStats.at(-1)?.namespace ?? "")
    const description = crumbs.at(-1)?.description

    return (
        <div>
            <Row>
                <Col span={3}>
                    status:
                </Col>
                <Col span={18}>
                    <Flex gap={12} align="baseline">
                        <ReviewSelector value={props.reviewFilter} onChange={setReviewFilter} />
                        {hasTableView(subsection) && <>
                            <Switch checked={viewContext.view === "table"} onChange={() => viewContext.setView(viewContext.view === "table" ? "datapoints" : "table")} />
                            table view
                        </>}
                    </Flex>
                </Col>
                <Col span={3}>
                </Col>

            </Row>
            <Row style={{ height: 32 }} >
                <Col span={3}>
                    section:
                </Col>
                <Col span={18}>
                    <ReviewBreadCrumb namespace={subsection} />
                </Col>
                <Col span={3}>
                    ({situationStats.at(1)?.count})
                </Col>
            </Row>
            <Row style={{ height: 32 }} >
                <Col span={3}>
                    subsection:
                </Col>
                <Col span={18}>
                    <ReviewBreadCrumb namespace={props.navLocator ?? ""} />
                </Col>
                <Col span={3}>
                    ({situationStats.at(-1)?.count})
                </Col>
            </Row>
            {description && <Row style={{ height: 32 }} >
                <Col span={21}>
                    <span style={{ fontStyle: "italic", color: "#999" }}>
                        {description}
                    </span>
                </Col>
            </Row>}

        </div>
    )
}

const ReviewSelector = (props: { value: string, onChange: (value: string) => void }) => {

    const selected = props.value
    const { items, selectedItem } = useMemo(() => {
        const allItems = [
            { label: "Accepted", key: "accepted" },
            { label: "Rejected", key: "rejected" },
            { label: "Corrected", key: "corrected" },
            { label: "Needs Review", key: "needs_review" },
            { label: "All", key: "all" }
        ] as const
        const items = allItems.filter(i => i.key !== selected)
        const selectedItem = allItems.find(i => i.key === selected)
        return { items, selectedItem }
    }, [selected])

    const menuProps = {
        items,
        onClick: (e: any) => {
            props.onChange(e.key)
        }
    }
    return (<Dropdown menu={menuProps} >
        <Button style={{ width: "150px" }}>
            <Space>
                <div style={{ width: "108px" }}>{selectedItem?.label}</div>
                <DownOutlined />
            </Space>
        </Button>
    </Dropdown>)
}

const ReviewBreadCrumb = (props:
    {
        namespace: string,
        style?: React.CSSProperties
    }) => {
    const crumbs = breadCrumb(props.namespace)
    return <Breadcrumb items={crumbs} style={props.style} />
}

const Subject = (props: { subject: { name?: string, role?: string } }) => {
    return props.subject.name
        ? <>
            <div style={{ fontSize: 14, fontWeight: "bold" }}>{props.subject.name}</div>
            <div style={{ fontSize: 10, color: "#999" }}>{props.subject.role}</div>
        </>
        : <div>role: <div style={{ fontSize: 14, fontWeight: "bold" }}>{props.subject.role}</div></div>

}
