import { createContext, useState } from "react"


export type UseViewSelector = ReturnType<typeof useViewSelector>
export const viewSelectorContext = createContext(undefined as unknown as UseViewSelector)

type View = "table" | "datapoints"
export function useViewSelector() {
    const [view, setView] = useState<View>("datapoints")
    return {view, setView}
}