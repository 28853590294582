import { get, post, put } from "./lib";
import { DocSpec } from "./rag";
import { JSONSchema7 } from "json-schema";
type Location = {from:number, to: number, locator: string}
export type DataItem = {
  Amount: string
  Name: string
  ReferenceText: string,
  Description:string,
  Year: string
  mapping?: string
  parentItem?: {relation: string, item: DataItem}
}
type Footnote = {
  reference: string,
  content: string,
  locations: Location[]  
}

export type FootnoteResult = Footnote & {
  dataItems: DataItem[]
  kind: string
  tables?: TableResult[]
}
export type TableResult = {
  location: Location
  footnotes: Footnote[]
  mapping?: Record<string,string>
  parsed: {
    headers: {value: string}[],
    datarows: Record<string, {value:string}>[]
  }
  parsedFootnotes?: FootnoteResult[]
}

type SectionCategory = string
type Chunk = {
  type: string, text:string, page: number
}
type Page = {idx: number, numberStr?: string, number?: number}
type CategorizedChunk = Chunk & {category?:SectionCategory}
export type DocMap = {
  toc: Toc
  chunks: CategorizedChunk[],
  pages: Page[],
  sections: Record<SectionCategory, Section[]>
  smoothedSections: Record<SectionCategory, Section[]>
  execSections: Record<SectionCategory, Section[]>
}

type Section = {
    category: SectionCategory,
    locators: string[],
    title: string,
    pages: string[],
}

type Result = {
  sct: TableResult,
  otherComp: {
    footnotes: FootnoteResult[]
    otherContent: {
      items: DataItem[]
      table?: TableResult
    }
  },
  docMap: DocMap
  evaluation: {
    column: string,
    ticker: string,
    officerName: string,
    fiscalYear: string,
    output: string | number  | undefined
    truth: string | number
    result: "match" | "no match" | "no data"
  }[][],
  toc: Toc,
  dataItems: DataItem[]

}

type Toc = {
  title: string,
  locators: string[],
  category: string,
  pages: string[],
  chapters: {
    title: string,
    category: string,
    pages: string[],
    locators: string[]
  }[]
}[]

export type Review<T=any> = {
  id: string,
  ticker: string,
  accessionNumber: string,
  assessment: "needs_review" | "accepted" | "rejected" | "corrected"
  datapointNamespace: string,
  datapointCoordinates: {name?: string, role?: string},
  reviewed: {id: string, item: {schema: JSONSchema7, value: T}},
  result: T,
  createdAt: string
  updatedAt: string
}

export type Extraction = {
  id: string,
  version: string,
  createdAt: string,
  ticker: string,
  accessionNumber: string
}

export type Fragment = {
  startLocator: string | undefined,
  endLocator: string | undefined,
  startWord: number | undefined,
  endWord: number | undefined,
  text: string,
}

export function processdef14a(query: DocSpec): Promise<Result> {
  return post(`/api/def14a/process.json`, query);
}

export function processUseCase(useCase: string, query: DocSpec) {
  return post(`/api/def14a/process/${useCase}.json`, query);
}

export function getReviews(query: DocSpec): Promise<{extraction: Extraction, reviews: Review[]}> {
  return get(`/api/def14a/reviews.json`, query);
}

export function submitReview(review: Review): Promise<Review> {
  return put(`/api/def14a/reviews/${review.id}.json`, review);
}

export function extractReviewFromFragment(docSpec: {ticker: string, accessionNumber: string}, extractionId: string, datapointNamespace: string, fragment: Fragment): Promise<Review[]> {
  return post(`/api/def14a/reviews.json`, {ticker: docSpec.ticker, accessionNumber: docSpec.accessionNumber, fragment, datapointNamespace, extractionId})
}