import { UpOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { Button, Flex, Input } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

export const SearchBox = (
    props: {
        onSearch: (value: string) => void
        onNext: () => void
        onPrev: () => void
        onClear: () => void
        total?: number
        current?: number
    }
) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const { onNext, onPrev, onSearch, onClear } = props

    const inputRef = useRef<any>(null);

    const handleSearch = useCallback((value: string, event: any, source: any) => {
        if (source === 'clear') {
            setSearchValue("");
            onClear();
        }
        if (value !== searchValue) {
            setSearchValue(value);
            onSearch(value);
        } else {
            onNext();            
        }
    }, [onClear, onNext, onSearch, searchValue])


    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowUp') {
                onPrev();
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                onNext();
                e.preventDefault();
            } else if (e.key !== 'Enter') {
                if (searchTimer) {
                    clearTimeout(searchTimer);
                }
                if (e.key !== 'Backspace') {
                    const value = (e?.target as HTMLInputElement)?.value
                    setSearchTimer(setTimeout(() => {
                        handleSearch(value, e, "keyboard");
                    }, 500));
                }
            }
        };

        const inputElement = inputRef.current?.input;
        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
            return () => inputElement.removeEventListener('keydown', handleKeyDown);
        }
    }, [handleSearch, onNext, onPrev, onSearch, searchTimer, searchValue]);



    return (
        <Flex gap={6} align="center">
            <Input.Search
                ref={inputRef}
                onSearch={handleSearch} 
                onClear={onClear}                               
                allowClear
                placeholder="Search"
                style={{ maxWidth: 500 }}
            />
            {props.total !== undefined
                ? (<>
                    <span style={{color: "gray"}}>{props.total ? `${(props.current||0) + 1}/${props.total}` : "0/0"}</span>
                    <Button icon={<UpOutlined style={{fontSize:12}}/>}  onClick={onPrev} />
                    <Button icon={<DownOutlined style={{fontSize:12}}/>}  onClick={onNext} />
                </>)
                : null
            }
        </Flex>
    )
}