import { VoidExpression } from "typescript"
import { post } from "./lib"

export type LogItem = {
    action:string,
    at: Date,
    ticker: string,
    accessionNumber: string
    searchTerm: string
    section: string,
    description: string
    payload: any
}


export function submitLog(item: LogItem): Promise<VoidExpression> {
    return post(`/api/log.json`, item);
}