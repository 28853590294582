import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DocPanel } from './DocPanel';
import { docPanelContext, useDetachedDocPanel } from './useApp';
import { Empty } from 'antd';
import { useState } from 'react';
import { ReferenceTextPanel } from './ReferenceTextPanel';
import { loggerContext, useDetachedLogger } from './useLogger';
export function DetachedDocPanelPage() {

    const logger = useDetachedLogger()
    const [queryClient] = useState(new QueryClient());

    const context = useDetachedDocPanel()
    const hasDoc = context.doc!!

    return (
        <QueryClientProvider client={queryClient}>
            <loggerContext.Provider value={logger}>
                <docPanelContext.Provider value={context}>
                    {hasDoc ? (<>
                        {/* <ReferenceTextPanel referenceText={context.referenceText} /> */}
                        <DocPanel {...context} />
                    </>) : <Empty description="No filing selected" />}
                </docPanelContext.Provider>
            </loggerContext.Provider>
        </QueryClientProvider>
    );
}


