import { get, post } from "./lib";

export function askRag(
  ticker: string,
  form: string,
  year: number,
  question: string,
  prompt: string
) {
  return get("/api/ask.json", {
    ticker,
    form,
    year,
    question,
    prompt,
  }) as unknown as Promise<{
    answer: {
      choices: {
        message: { content: string };
      }[];
    };
    results: { start_line: number; end_line: number }[];
    lines: { line_number: number; text: string }[];
  }>;
}

// The API supports multiple documents, but the front end
// code only considers the first doc and results/lines
export function sendChatMessage(
  message: string,
  docs: { ticker: string; form: string; year: number }[],
  history: string[] // question,response,...
) {
  // dirty dirty proof of concept
  const engine = window.location.search.includes("claude")
    ? "claude"
    : "openai";
  return post(`/api/chat.json`, {
    message,
    docs,
    history,
    engine,
  }) as unknown as Promise<{
    answer: string;
    results: { start_line: number; end_line: number }[][]; // per doc w/
    lines: { line_number: number; text: string }[][]; // same doc index
    messageId: string;
  }>;
}

export function searchDocument(
  ticker: string,
  form: string,
  year: number,
  query: string
) {
  return get("/api/search.json", {
    ticker,
    form,
    year,
    query,
  }) as unknown as Promise<{
    results: { start_line: number; end_line: number }[];
    lines: { line_number: number; text: string }[];
  }>;
}

export function getLines(
  ticker: string,
  form: string,
  year: number,
  range: { start: number; end: number }
) {
  return post("/api/lines.json", {
    ticker,
    form,
    year,
    range,
  }) as unknown as Promise<{
    lines: { line_number: number; text: string }[];
  }>;
}

export type DocSpec = {
  ticker: string,
  form: string,
  year?: number
} | {
  ticker: string,
  accessionNumber: string
  form?: string
  year?: number
}

export function getFiling(query: DocSpec): Promise<{ html: string, filing: DocSpec }> {
  return get(`/api/filing.json`, query);
}

export function getFilingByAccessionNumber(query: {
  ticker: string;
  accessionNumber: string;
}): Promise<{ html: string }> {
  return get(`/api/filing-by-accession-number.json`, query);
}

export function getDocStatus(query: {
  ticker: string;
  year: number;
  form: string;
}): Promise<{ status: string }> {
  return get(`/api/doc.json`, query);
}

export function saveFeedback(rating: number, messageId: string) {
  return post(`/api/feedback.json`, { rating, messageId });
}

export function getProgressReports(): Promise<
  Record<
    string,
    {
      results: any;
      submissions: Array<{
        question: string;
        answer: string;
        claude: { answer: string; grade: string };
        openai: { answer: string; grade: string };
      }>;
    }
  >
> {
  return get(`/api/progress-reports.json`);
}
