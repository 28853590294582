
import { useContext } from "react"
import { formContext, FormItem, FormItemArray, Located } from "../DynamicForm"
import { Flex, Table } from "antd"
import { Review } from "../../../api-clients/def14a"
import { ReviewableQualifyingStock, ReviewableSog, ReviewableTarget, ReviewableTimeFrame, ReviewableWithHoldingRule } from "@chester/shared/def14a/sog/review"
import { valueOf } from "../useReviews"

export const SOGTarget = () => {
    const ctx = useContext(formContext)
    const value = ctx.getValues()
    return (
        <Located path="">
            <FormItemArray path="items" label="targets" renderItem={
                (index) => <>
                    <FormItem path={`items.${index}.kind`} />
                    <FormItem path={`items.${index}.amount`} />
                </>
            } />
            {value.items.length > 1 && <FormItem path="combinedWith" />}
        </Located>
    )
}


export const SOGTimeFrame = () => {
    return (
        <Located path="">
            <Flex gap={4}>
                <FormItem path="quantity" />
                <FormItem path="unit" />
            </Flex>
        </Located>
    )
}

export const SOGQualifying = () => {
    return (
        <FormItemArray path="items" renderItem={
            (index) => (
                <>
                    <FormItem path={`items.${index}.kind`} />
                    <FormItem path={`items.${index}.vestingStatus`} />
                    <FormItem path={`items.${index}.percentage`} />
                </>
            )
        } />
    )
}

export const SOGWithHolding = () => {
    return (
        <FormItemArray path="items" renderItem={
            (index) => (
                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: 4, flexWrap: "wrap" }}>
                        <FormItem path={`items.${index}.beginningAt`} />
                        <FormItem path={`items.${index}.until`} />
                        <FormItem path={`items.${index}.holdingPeriod`} label="holding period (months)" />
                        <FormItem path={`items.${index}.percentage`} />
                        <FormItem path={`items.${index}.unit`} />
                        <FormItem path={`items.${index}.condition`} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>

                    </div>
                </div>
            )
        } />
    )
}


//-------------------------------------- for Clic table
const clicGuidelinesColumns = [
    { title: "Role", dataIndex: "role", key: "role", className: "clic-dark", fixed: "left" },
    { title: "Officer name", dataIndex: "name", key: "name", className: "clic-officer", fixed: "left" },
    { title: "Salary multiple", dataIndex: "salaryMultiple", key: "salaryMultiple" },
    { title: "Shares (#)", dataIndex: "numberOfShares", key: "numberOfShares" },
    { title: "Share Value ($)", dataIndex: "valueOfShares", key: "valueOfShares" },
    { title: "Time frame (years)", dataIndex: "timeFrame", key: "timeFrame" },
    { title: "Qualifying shares", dataIndex: "qualifyingShares", key: "qualifyingShares" },
    { title: "Ownership method", dataIndex: "ownershipMethod", key: "ownershipMethod" },
    { title: "Options", dataIndex: "options", key: "options" },
    { title: "Percent", dataIndex: "optionsPercent", key: "optionsPercent" },
    { title: "RSUs", dataIndex: "rsus", key: "rsus" },
    { title: "Percent", dataIndex: "rsusPercent", key: "rsusPercent" },
    { title: "PSUs", dataIndex: "psus", key: "psus" },
    { title: "Percent", dataIndex: "psusPercent", key: "psusPercent" },
    { title: "Def shares", dataIndex: "defShares", key: "defShares" },
    { title: "Percent", dataIndex: "defSharesPercent", key: "defSharesPercent" },
] as const

type Value = number | string | undefined


type ClicGuidelinesRow = Record<typeof clicGuidelinesColumns[number]["dataIndex"], Value | string>


const clicWithholdingColumns = [
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Officer name", dataIndex: "name", key: "name" },
    {
        title: "Until guidelines are met", dataIndex: "untilGuidelinesAreMet", children: [
            { title: "Type of shares", dataIndex: "untilTypeOfShares", key: "untilTypeOfShares" },
            { title: "Percentage of shares", dataIndex: "untilPercentage", key: "untilPercentage" },
            { title: "Holding period", dataIndex: "untilHoldingPeriod", key: "untilHoldingPeriod" },
        ]
    },
    {
        title: "After vesting", dataIndex: "afterVestingOrExercise", children: [
            { title: "Type of shares", dataIndex: "afterTypeOfShares", key: "afterTypeOfShares" },
            { title: "Percentage of shares", dataIndex: "afterPercentage", key: "afterPercentage" },
            { title: "Holding period", dataIndex: "afterHoldingPeriod", key: "afterHoldingPeriod" },
        ]
    },
    {
        title: "Until retirement", dataIndex: "untilRetirement", children: [
            { title: "Type of shares", dataIndex: "untilRetirementTypeOfShares", key: "untilRetirementTypeOfShares" },
            { title: "Percentage of shares", dataIndex: "untilRetirementPercentage", key: "untilRetirementPercentage" },
            { title: "Holding period", dataIndex: "untilRetirementHoldingPeriod", key: "untilRetirementHoldingPeriod" },
        ]
    },
    {
        title: "If guidelines are not met", dataIndex: "ifGuidelinesAreNotMet", children: [
            { title: "Type of shares", dataIndex: "ifGuidelinesAreNotMetTypeOfShares", key: "ifGuidelinesAreNotMetTypeOfShares" },
            { title: "Percentage of shares", dataIndex: "ifGuidelinesAreNotMetPercentage", key: "ifGuidelinesAreNotMetPercentage" },
            { title: "Holding period", dataIndex: "ifGuidelinesAreNotMetHoldingPeriod", key: "ifGuidelinesAreNotMetHoldingPeriod" },
        ]
    },
] as const


type ClicWithholdingLeafKeys<T> = 
    T extends readonly (infer U)[] 
        ? ClicWithholdingLeafKeys<U>
        : T extends { key: string }
            ? T["key"]
            : T extends { children: readonly any[] }
                ? ClicWithholdingLeafKeys<T["children"]>
                : never;

// all the 'key' for leaf nodes
type ClicWithholdingLeafKeysResult = ClicWithholdingLeafKeys<typeof clicWithholdingColumns>;
type ClicWithholdingRow = Record<ClicWithholdingLeafKeysResult, Value | string>


const identifier = (review: Review<any>) => {
    const { name, role } = review.datapointCoordinates
    return name || role
}

export const asClicGuidelinesRows = (reviews: Review<ReviewableSog>[]): ClicGuidelinesRow[] => {
    const ret: ClicGuidelinesRow[] = []
    const identifiers = [...new Set(reviews.map(identifier))]
    for (const id of identifiers) {
        const peopleReviews = reviews.filter(r => identifier(r) === id)
        if (peopleReviews.length === 0) continue
        const { name, role } = peopleReviews[0].datapointCoordinates
        const row: Partial<ClicGuidelinesRow> = {
            name,
            role,
        }

        const targetReviews = peopleReviews.filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.sog.target"])
        // take the first one. TODO: do we expect ever more than one?
        const target = targetReviews.at(0)
        if (target) {
            const val = valueOf(target) as ReviewableTarget
            row.salaryMultiple = val.items.find(i => i.kind === "salary multiple")?.amount
            row.numberOfShares = val.items.find(i => i.kind === "shares number (#)")?.amount
            row.valueOfShares = val.items.find(i => i.kind === "shares value ($)")?.amount

            row.ownershipMethod = val.combinedWith
        }

        const timeFrameReviews = peopleReviews.filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.sog.timeFrame"])
        const timeFrame = timeFrameReviews.at(0)
        if (timeFrame) {
            const val = valueOf(timeFrame) as ReviewableTimeFrame
            const unitInYears = val.unit === "years" ? val.quantity : val.unit === "months" ? val.quantity / 12 : val.quantity / 4
            row.timeFrame = unitInYears.toFixed(1)
        }

        const qualifyingReviews = peopleReviews.filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.sog.qualifying"])
        const qualifying = qualifyingReviews.at(0)
        if (qualifying) {
            const val = valueOf(qualifying) as ReviewableQualifyingStock
            row.options = val.items.find(i => i.kind === "option")?.vestingStatus
            row.optionsPercent = val.items.find(i => i.kind === "option")?.percentage

            row.rsus = val.items.find(i => i.kind === "RSU")?.vestingStatus
            row.rsusPercent = val.items.find(i => i.kind === "RSU")?.percentage

            row.psus = val.items.find(i => i.kind === "PSU")?.vestingStatus
            row.psusPercent = val.items.find(i => i.kind === "PSU")?.percentage

            row.defShares = val.items.find(i => i.kind === "deferred")?.vestingStatus
            row.defSharesPercent = val.items.find(i => i.kind === "deferred")?.percentage
        }

        if (target || timeFrame || qualifying) {
            ret.push(row as ClicGuidelinesRow)
        }
    }

    return ret
}

export const asClicWithholdingRows = (reviews: Review<ReviewableSog>[]):ClicWithholdingRow[] => {
    const ret: ClicWithholdingRow[] = []    
    const identifiers = [...new Set(reviews.map(identifier))]
    for (const id of identifiers) {
        const peopleReviews = reviews.filter(r => identifier(r) === id)
        if (peopleReviews.length === 0) continue
        const { name, role } = peopleReviews[0].datapointCoordinates
        const row: Partial<ClicWithholdingRow> = {
            name,
            role,
        }

        const withHoldingReviews = peopleReviews.filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.sog.withHoldingRules"])
        for (const withHolding of withHoldingReviews) {
            const val = valueOf(withHolding) as ReviewableWithHoldingRule
            // figure out which scenario this is
            const scenario = figureScenario(val)
            if (scenario){
                // TODO ever expect more than one item?
                if (val.items.length > 1) {
                    console.log("more than one item in with holding rule", val)
                }
                const item = val.items[0]
                if (scenario === "untilTarget"){
                    row.untilTypeOfShares = item.unit
                    row.untilPercentage = item.percentage
                    row.untilHoldingPeriod = item.holdingPeriod
                }
                if (scenario === "afterTarget"){
                    row.afterTypeOfShares = item.unit
                    row.afterPercentage = item.percentage
                    row.afterHoldingPeriod = item.holdingPeriod
                }
                if (scenario === "afterRetirement"){
                    row.untilRetirementTypeOfShares = item.unit
                    row.untilRetirementPercentage = item.percentage
                    row.untilRetirementHoldingPeriod = item.holdingPeriod
                }
                if (scenario === "ifTargetUnmet"){
                    row.ifGuidelinesAreNotMetTypeOfShares = item.unit
                    row.ifGuidelinesAreNotMetPercentage = item.percentage
                    row.ifGuidelinesAreNotMetHoldingPeriod = item.holdingPeriod
                }

                ret.push(row as ClicWithholdingRow)
            } else {
                console.log("could not identify scenario", val)
            }
        }
    }
    return ret
}


export const SogGuidelinesClicTable = (props: {reviews: Review<ReviewableSog>[]}) => {
    const datasource = asClicGuidelinesRows(props.reviews)
    const columns = [...clicGuidelinesColumns]
    return <Table dataSource={datasource} columns={columns} pagination={false} scroll={{x: 'max-content'}} />
}

export const SogWithholdingClicTable = (props: {reviews: Review<ReviewableSog>[]}) => {
    const datasource = asClicWithholdingRows(props.reviews)
    const columns = [...clicWithholdingColumns]
    return <Table dataSource={datasource} columns={columns} pagination={false} scroll={{x: 'max-content'}} />
}



const figureScenario = (rule: ReviewableWithHoldingRule) => {
    const from = rule.items[0].beginningAt
    const until = rule.items[0].until
    const condition = rule.items[0].condition

    if (condition === "target is not met"){
        return "ifTargetUnmet"
    }
    if (from === "start date"){
        if (until === "target is met"){
            return "untilTarget"
        }
        return undefined
    } else if (from === "target is met"){
        if (until === "retirement date" || until ===undefined){
            return "afterTarget"
        } 
        return undefined
    } else if (from === "retirement date"){
        return "afterRetirement"
    } else if (from === "fails to meet target"){
        return "ifTargetUnmet"
    } else if (from === "end of grace period"){
        if (until === "target is met"){
            return "ifTargetUnmet"
        }
        return undefined
    }


    return undefined
}

export const SogClicTable = (props: {reviews: Review<ReviewableSog>[]}) => {
    return <Flex gap={16} vertical>
        <SogGuidelinesClicTable reviews={props.reviews} />
        <SogWithholdingClicTable reviews={props.reviews} />
    </Flex>
}