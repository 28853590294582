import { FormItem } from "../DynamicForm"
import { Located } from "../DynamicForm"


export const FiscalYear = () => {
    return (
        <Located path="">
            <FormItem path="fiscalYear" date={true} />
            <FormItem path="start" />
        </Located>
    )
}

export const NEO = () => {
    return (
        <Located path="">
            <FormItem path="name" />
            <FormItem path="role" />
            <FormItem path="change" />
        </Located>
    )
}