import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { submitLog } from "src/api-clients/log"

// functionality to capture user actions of interest
// context of interest is maintained and emitted with each action
// clients can
// -  call useLoggerContext to set context 
// -  call useLogger to log an action

// Define possible context keys
export type ContextKey = 'ticker' | 'accessionNumber' | 'section' | 'searchTerm' | 'multiWindow'

// Create a type for the context store
export type LoggerContext = {
    [K in ContextKey]?: any;
}

// Helper hook for components to set their context
export const useLoggerContext = (key: ContextKey, value: any) => {
    const ctx = useContext(loggerContext)
    const updateLoggerContext = ctx ? ctx.updateLoggerContext : undefined
    useEffect(() => {
        updateLoggerContext?.(key, value)
    }, [key, value, updateLoggerContext])
}


type UseLogger = ReturnType<typeof useLogger>
export const loggerContext = createContext<UseLogger>({ log: () => { }, updateLoggerContext: () => { } })


type Action =
    "navigate" |
    "docSearch" |
    "accept" |
    "reject" |
    "create" |
    "tabSearch"
    ;



export const useLogger = () => {
    const [tabId, setTabId] = useState<string>()
    useEffect(() => {
        console.log("creating a new tab id")
        setTabId(crypto.randomUUID())
    }, [])
    const [context, setContext] = useState<LoggerContext>({})

    const updateLoggerContext = useCallback((key: ContextKey, value: any) => {
        setContext(prev => {
            console.log("updating context", key, value, prev)
            return {
                ...prev,
                [key]: value
            }
        })
    }, [])

    const log = useCallback((action: Action, data: Record<string, any>) => {
        console.log(action, data)
        const payload = {
            ...context,
            ...data,
            action,
            at: new Date(),
            tabId
        }
        const logItem = {
            action: payload.action,
            at: payload.at,
            ticker: payload.ticker,
            accessionNumber: payload.accessionNumber,
            searchTerm: payload.searchTerm,
            section: payload.section,
            description: "description" in payload ? payload.description as string : "",
            payload: payload
        }
        submitLog(logItem)

    }, [tabId, context])

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin === window.origin) {
                if (event.data.action === 'LOG_MESSAGE') {
                    console.log("received log message", event.data.payload)
                    log(event.data.payload.action, event.data.payload)
                } else if (event.data.action === 'UPDATE_LOGGER_CONTEXT') {
                    console.log("received update logger context message", event.data.payload)
                    updateLoggerContext(event.data.payload.key, event.data.payload.value)
                }
            }
        }
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [log, updateLoggerContext])

    return {
        log,
        updateLoggerContext,
    }
}

export const useDetachedLogger = () => {
    return {
        log: (action: Action, data: Record<string, any>) => {
            window.opener?.postMessage({ action: "LOG_MESSAGE", payload: { action, ...data } }, '*')
        },
        updateLoggerContext: (key: ContextKey, value: any) => {
            window.opener?.postMessage({ action: "UPDATE_LOGGER_CONTEXT", payload: { key, value } }, '*')
        }
    }

}
