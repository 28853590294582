import { useCallback, useState } from "react";
import { asLocator } from "../../utils/location";

export type RangeSelection = {
    startLocator: string | undefined,
    endLocator: string | undefined,
    startWord: number | undefined,
    endWord: number | undefined,
    text: string,
    rangeRect: DOMRect,
}

export function useRangeSelection() {
    const [rangeSelection, setRangeSelection] = useState<RangeSelection | undefined>(undefined);

    const captureRangeSelection = () => {
        const selection = window.getSelection()
            if (!selection) return
            if (selection.rangeCount === 0) return
            const selectedText = selection.toString().trim();
            const range = selection.getRangeAt(0);
            const locator = asLocator(range)
            setRangeSelection({
                startLocator: locator.startLocator,
                endLocator: locator.endLocator,
                startWord: locator.startWord,
                endWord: locator.endWord,
                text: selectedText,
                rangeRect: range.getBoundingClientRect(),
            });
    }

    const clearRangeSelection = useCallback(() => {
        window.getSelection()?.removeAllRanges()
        setRangeSelection(undefined)
    }, [setRangeSelection])

    return {
        // capture the window selection into rangeSelection
        captureRangeSelection,
        rangeSelection,
        clearRangeSelection,
    }
}