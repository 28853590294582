import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useContext, useEffect, useMemo } from "react";
import { appContext } from "./useApp";
import { useResults } from "./useResults";
import { LoadingOutlined } from "@ant-design/icons";
import { UseCaseConnector } from "./use-cases/types";
import { lookup } from "./use-cases/common";

export const ResultPanel = () => {
    const context = useContext(appContext)
    const { setHighlights, setFocus } = context
    const useCase = context.useCase
    const { data, ready } = useResults(useCase, context.doc)

    const connector: UseCaseConnector<any> | undefined = useMemo(() => {
        return lookup(useCase)
    }, [useCase])

    const loaded = useMemo(() => {
        return ready && data && connector
    }, [ready, data, connector])

    // figure all the locations
    useEffect(() => {
        const allLocations = connector?.figureLocations(data) || []
        const toHighlight = allLocations
        setHighlights(toHighlight)
        if (toHighlight.length > 0) {
            setFocus({ locator: toHighlight[0].startLocator })
        }
    }
        , [data, setHighlights, setFocus, connector])

    return (
        <ScrollArea.Root className="ScrollAreaRoot">
            <ScrollArea.Viewport className="ScrollAreaViewport">
                {loaded && connector ? <connector.DataView data={data} /> : <LoadingOutlined />}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
                className="ScrollAreaScrollbar"
                orientation="vertical"
            >
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Scrollbar
                className="ScrollAreaScrollbar"
                orientation="horizontal"
            >
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
        </ScrollArea.Root>
    )
}

