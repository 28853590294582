
// TODO - this is a duplicate of datapointMinimap in chester
// expose it e.g. via shared package
// doc - top section - subsection

import { ConfigProvider, Tree } from "antd"


// each item can have a label field
const datapointMinimap = {
    def14a: {
        background: {
            fiscalYear: {
                label: "Fiscal Year"
            },
            neos: {
                label: "Named Executive Officers"
            }
        },
        cda: {
            label: "C,D & A",
            lti: {
                label: "Long Term Incentives",
                principle: {
                    label: "Peer Group Benchmarks"
                },
                targets: {
                    label: "Compensation Targets"
                },
                payouts: {
                    label: "Payouts"
                }
            },
            sog: {
                label: "Stock Ownership Guidelines",
                target: {
                    label: "Target"
                },
                timeFrame: {
                    label: "Time Frame"
                },
                qualifying: {
                    label: "Qualifying Stocks",
                    description: "Stocks that count towards the stock ownership requirements"
                },
                withHoldingRules: {
                    label: "Withholding Rules"
                }
            }
        }
    }
} as const

// same as above, but with
// {title, key, children}
const minimapTreeOld = Object.entries(datapointMinimap.def14a)
    .filter(([section, subsections]) => section !== "label")
    .map(([section, subsections]) => ({
        title: (section as any).label || section,
        key: `def14a.${section}`,
        children: Object.entries(subsections)
            .filter(([subsection, item]) => subsection !== "label")
            .map(([subsection, item]) => ({
                title: (item as any).label || subsection,
                key: `def14a.${section}.${subsection}`,
                children: Object.entries(item)
                    .filter(([child, childItem]) => child !== "label")
                    .map(([child, childItem]) => ({
                        title: (childItem as any).label || child,
                        key: `def14a.${section}.${subsection}.${child}`,
                    }))
            }))
    }))


const buildTreeNode = (key: string, value: any): any => {
    if (typeof value !== 'object' || value === null) return null;

    const children = Object.entries(value)
        .filter(([k]) => k !== 'label')
        .map(([k, v]) => buildTreeNode(`${key}.${k}`, v))
        .filter(Boolean);

    return {
        title: value.label || key.split('.').pop(),
        key: key,
        ...(children.length > 0 && { children })
    };
};

const minimapTree = Object.entries(datapointMinimap.def14a)
    .filter(([section]) => section !== 'label')
    .map(([section, value]) =>
        buildTreeNode(`def14a.${section}`, value)
    );

const findNode = (key: string, tree: { key: string, children?: { key: string }[] }[] = minimapTree): { key: string, children?: { key: string }[] } | undefined => {
    if (!tree) return undefined
    const atLevel = tree.find(node => node.key === key)
    if (atLevel) return atLevel
    for (const node of tree) {
        for (const child of node.children || []) {
            const found = findNode(key, [child])
            if (found) return found
        }
    }
    return undefined
}
const keyToNamespace = (key: string) => {
    const node = findNode(key)
    if (!node) return undefined
    let current = node
    while (current.children) {
        current = current.children[0]
    }
    return current.key
}

export const defaultNamespace = () => keyToNamespace(minimapTree[0].key)

//minimap will always land you on a leaf
export const Minimap = (props: { onSelect: (namespace: string) => void, namespace: string }) => {
    const theme = { components: { Tree: { nodeSelectedBg: "#00c8ff1f" } } }
    return (
        <ConfigProvider theme={theme}>
            <Tree style={{ padding: 6 }}
                treeData={minimapTree}
                selectedKeys={[props.namespace]}
                defaultExpandAll={true}
                onClick={(_, info) => props.onSelect(keyToNamespace(info.key) || info.key)}
            />
        </ConfigProvider>
    )
}


export const breadCrumb = (namespace: string) => {
    if (namespace === "") return []
    const parts = namespace.split(".")
    const ret = []
    let current = datapointMinimap as any
    let idx = 0
    for (const part of parts) {
        idx++
        current = current[part]

        ret.push({
            title: current.label ?? part,
            description: current.description,
            part: parts.slice(0, idx).join(".")
        })
    }
    ret.shift()
    return ret
}   
