import { useContext, useEffect, useMemo } from "react";

import { appContext } from "./useApp";
import { firstLocation, reviewsContext, UseReviews, valueOf } from "./useReviews";
import { DownOutlined } from "@ant-design/icons";
import Button from "antd/es/button/button";

import { Breadcrumb, Col, Dropdown, Flex, Row, Space, Switch } from "antd";
import { ClicTable } from "./renderers";
import { breadCrumb } from "./Minimap";
import { viewSelectorContext } from "./useViewSelector";

export const TablePanel = () => {
    const context = useContext(appContext)
    const ctx = useContext(reviewsContext)

    const {navLocator, reviewFilter, data, inSubsectionScope} = ctx
    const {setFocus, setHighlights} = context

    useEffect(() => {        
        setHighlights([])
    }, [navLocator, reviewFilter, setHighlights])

    useEffect(()=>{
        if (data && data.length > 0){
            const loc = firstLocation(valueOf(data[0]))
            if (loc){
                setFocus({locator: loc.startLocator})
            }
        }
    },[data, setFocus])


    return (
        <div style={{ margin: 12, height: "100%" }}>
            <Flex vertical gap={30}>
                <Situation {...ctx} />
                <ClicTable reviews={inSubsectionScope ?? []} />
            </Flex>

            {/* <ScrollArea.Root style={{ height: `calc(100% - 80px)` }}>
                <ScrollArea.Viewport style={{ height: "100%" }}>
                    <ClicTable reviews={data ?? []} />
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                    className="ScrollAreaScrollbar"
                    orientation="vertical">
                    <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                    className="ScrollAreaScrollbar"
                    orientation="horizontal">
                    <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
            </ScrollArea.Root> */}
        </div>
    )
}

const Situation = (props: UseReviews) => {
    const { situationStats, setReviewFilter } = props
    const viewContext = useContext(viewSelectorContext)
    return (
        <div>
            <Row>
                <Col span={3}>
                    status:
                </Col>
                <Col span={18}>
                    <Flex gap={12} align="baseline">
                        <ReviewSelector value={props.reviewFilter} onChange={setReviewFilter} />
                        <Switch checked={viewContext.view === "table"} onChange={()=>viewContext.setView(viewContext.view === "table" ? "datapoints" : "table")} /> table view
                    </Flex>
                </Col>
                <Col span={3}>
                </Col>

            </Row>
            <Row style={{ height: 32 }} >
                <Col span={3}>
                    section:
                </Col>
                <Col span={5}>
                    <ReviewBreadCrumb namespace={situationStats.at(1)?.namespace ?? ""} />
                </Col>
                <Col span={3}>
                    ({situationStats.at(1)?.count})
                </Col>
            </Row>           

        </div>
    )
}

const ReviewSelector = (props: { value: string, onChange: (value: string) => void }) => {

    const selected = props.value
    const { items, selectedItem } = useMemo(() => {
        const allItems = [
            { label: "Accepted", key: "accepted" },
            { label: "Rejected", key: "rejected" },
            { label: "Corrected", key: "corrected" },
            { label: "Needs Review", key: "needs_review" },
            { label: "All", key: "all" }
        ] as const
        const items = allItems.filter(i => i.key !== selected)
        const selectedItem = allItems.find(i => i.key === selected)
        return { items, selectedItem }
    }, [selected])

    const menuProps = {
        items,
        onClick: (e: any) => {
            props.onChange(e.key)
        }
    }
    return (<Dropdown menu={menuProps} >
        <Button style={{ width: "150px" }}>
            <Space>
                <div style={{ width: "108px" }}>{selectedItem?.label}</div>
                <DownOutlined />
            </Space>
        </Button>
    </Dropdown>)
}

const ReviewBreadCrumb = (props:
    {
        namespace: string,
        style?: React.CSSProperties
    }) => {
    const crumbs = breadCrumb(props.namespace)
    return <Breadcrumb items={crumbs} style={props.style} />
}

