import { useContext } from "react";
import { appContext } from "../useApp";
import { Table } from "antd";
import { Located } from "./types";
import { renderApplicableTo, RenderLocated } from "./common";

import { SalaryTarget } from "@chester/shared/def14a/salary";

const figureLocations = (data: SalaryTarget[]) => {
    if (!data) return []
    return data.flatMap(d => [d.peerGroupTarget]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location))
        .filter(Boolean) as Located["location"][]
}

const SalaryResults = ({ data }: { data: SalaryTarget[] }) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d) => ({
        applicableTo: {
            value: renderApplicableTo(d.applicableTo)
        },
        peerGroupTarget: {
            value: d.peerGroupTarget
                ? typeof (d.peerGroupTarget?.value) == "string" || typeof (d.peerGroupTarget?.value) === "number"
                    ? d.peerGroupTarget?.value
                    : `${d.peerGroupTarget.value.from} - ${d.peerGroupTarget.value.to} ${d.peerGroupTarget.value.kind === "percentile" ? "percentile" : "percentage of median"}`
                : "",
            location: (d.peerGroupTarget as Record<string, any>)?.location
        },
    }))

    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["applicableTo", "peerGroupTarget"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (<Table dataSource={datasource} columns={columns} />)
}

export const SalaryUseCase = {
    DataView: SalaryResults,
    figureLocations
}