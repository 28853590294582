import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getFiling } from "../../api-clients";
import { RangeSelection } from "./useRangeSelection";
import { useLoggerContext } from "./useLogger";

export type UseDocPanel = ReturnType<typeof useDetachedDocPanel>
export const docPanelContext = createContext(undefined as unknown as UseDocPanel)

type SelectionAction = 'APPLY_SELECTION' | 'CANCEL_SELECTION'

// context for a DocPanel rendered in a window spawned from the main window
// non-detached doc panels call useApp (which includes the functionality found in here)
// TODO -- maybe extract the docPanel context out of useApp
export function useDetachedDocPanel() {

    // receive messages from the parent window
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin === window.origin) {
                const { action, payload } = event.data;
                if (action === 'SET_DOC') {
                    setDoc(payload)
                } else if (action === 'SET_HIGHLIGHTS') {
                    setHighlights(payload)
                } else if (action === 'SET_FOCUS') {
                    setFocus(payload)
                } else if (action === 'SET_HOVER') {
                    setHover(payload)
                } else if (action === 'SET_REFERENCE_TEXT') {
                    setReferenceText(payload)
                } else if (action === 'SET_SELECTION_ENABLED') {
                    setSelectionEnabled(payload)
                }
            }
        };

        if (window.opener) {
            window.addEventListener('message', handleMessage);
            window.opener.postMessage('CHILD_WINDOW_READY', '*');
        }

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    const [doc, setDoc] = useState<Doc>();
    const [highlights, setHighlights] = useState<{ startLocator: string, endLocator: string }[]>([])
    const [focused, setFocus] = useState<{ locator: string } | undefined>(undefined)
    const [hovered, setHover] = useState<{ startLocator: string | undefined, endLocator: string | undefined }[]>([])
    const [referenceText, setReferenceText] = useState<string | undefined>(undefined)
    const [selectionEnabled, setSelectionEnabled] = useState(false)

    const onSelectionAction = (action:SelectionAction, selection?:RangeSelection)=>{
        window.opener?.postMessage({action, payload:selection}, '*')
    }

    return {
        doc,
        setDoc,
        // all the highligths
        setHighlights,
        highlights,
        // what to scroll to
        focused,
        setFocus,
        // what to show as a 'current' highlight
        hovered,
        setHover,
        // the reference text for a current highlight
        referenceText,
        setReferenceText,
        // callback when a selection is made or cancelled
        onSelectionAction,
        // is doc fragment selection enabled
        selectionEnabled,
        setSelectionEnabled,
    }
}



export type UseApp = ReturnType<typeof useApp>
export const appContext = createContext(undefined as unknown as UseApp)

const actions = ['SET_DOC', 'SET_HIGHLIGHTS', 'SET_FOCUS', 'SET_HOVER', 'SET_REFERENCE_TEXT', 'SET_SELECTION_ENABLED'] as const
type Action = typeof actions[number]

export function useApp() {

    const {
        doc,
        setDoc,
        setHighlights,
        highlights,
        focused,
        setFocus,
        hovered,
        setHover,
        referenceText,
        setReferenceText,
        selectionEnabled,
        setSelectionEnabled,
    } = useDetachedDocPanel()

    const [searchParams, setSearchParams] = useSearchParams();
    const [docPanelWindow, setDocPanelWindow] = useState<Window | undefined>(undefined)

    const [referenceTextEditCallback, setReferenceTextEditCallback] = useState<((action:string, selection?:RangeSelection)=>void) | undefined>(undefined)

    // just a pass-through
    const onSelectionAction = useCallback((action:string, selection?:RangeSelection)=>{
        if (selection?.text && referenceTextEditCallback) {
            referenceTextEditCallback(action, selection)
        }
    }, [referenceTextEditCallback])


    const sendMessage = useCallback((action: Action, payload: any) => {
        if (!docPanelWindow) return
        docPanelWindow.postMessage({ action, payload }, '*')
    }, [docPanelWindow])

    useEffect(() => {
        sendMessage('SET_DOC', doc)
    }, [doc, sendMessage])

    useEffect(() => {
        sendMessage('SET_HIGHLIGHTS', highlights)
    }, [highlights, sendMessage])

    useEffect(() => {
        sendMessage('SET_FOCUS', focused)
    }, [focused, sendMessage])

    useEffect(() => {
        sendMessage('SET_HOVER', hovered)
    }, [hovered, sendMessage])

    useEffect(() => {
        sendMessage('SET_REFERENCE_TEXT', referenceText)
    }, [referenceText, sendMessage])

    useEffect(() => {
        sendMessage('SET_SELECTION_ENABLED', selectionEnabled)
    }, [selectionEnabled, sendMessage])

    // listen to events from the doc panel
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin === docPanelWindow?.origin) {
                // when the doc panel is ready, send all the current state
                if (event.data === 'CHILD_WINDOW_READY') {
                    sendMessage('SET_DOC', doc)
                    sendMessage('SET_HIGHLIGHTS', highlights)
                    sendMessage('SET_FOCUS', focused)
                    sendMessage('SET_HOVER', hovered)
                    sendMessage('SET_REFERENCE_TEXT', referenceText)
                    sendMessage('SET_SELECTION_ENABLED', selectionEnabled)
                } else if (event.data.action === 'CANCEL_SELECTION') {
                    onSelectionAction('CANCEL_SELECTION')
                } else if (event.data.action === 'APPLY_SELECTION') {
                    onSelectionAction('APPLY_SELECTION', event.data.payload)
                }
            }
        }
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        };
    }, [docPanelWindow, doc, highlights, focused, hovered, referenceText, sendMessage, onSelectionAction, selectionEnabled])

    useEffect(() => {
        const ticker = searchParams.get("ticker")
        const accessionNumber = searchParams.get("accessionNumber")
        const initial = ticker && accessionNumber ? { ticker, accessionNumber } : undefined
        if (!doc && initial) {
            getFiling(initial).then(filing => setDoc(filing.filing as Doc))
        }
    }, [doc, searchParams, setDoc])

    const open = useCallback(async (doc: Doc) => {
        const { filing } = await getFiling(doc)
        const docSpec = { ...doc, accessionNumber: (filing as any).accessionNumber }
        setDoc(docSpec);
        setSearchParams({ ticker: docSpec.ticker, accessionNumber: docSpec.accessionNumber })
    }, [setDoc, setSearchParams]);

    const detachWindow = useCallback(() => {
        if (docPanelWindow) {
            docPanelWindow.close()
            setDocPanelWindow(undefined)
        }

        const docWindow = window.open('/reviewer/detached', undefined, `width=${window.outerWidth * 0.45},height=${window.outerHeight}`);
        if (docWindow) {
            docWindow.document.title = 'Detached Document Panel'
            // Store the reference to the window
            setDocPanelWindow(docWindow);
        }
    }, [docPanelWindow])

    const isDocPanelDetached = useMemo(() => docPanelWindow !== undefined, [docPanelWindow])

    const reattachWindow = useCallback(() => {
        setDocPanelWindow(undefined)
        docPanelWindow?.close()
    }, [docPanelWindow])

    useLoggerContext('accessionNumber', doc?.accessionNumber)
    useLoggerContext('ticker', doc?.ticker)
    useLoggerContext('multiWindow', isDocPanelDetached)

    return {
        // app concerns
        open,
        close: () => {
            setDoc(undefined)
            setSearchParams({})
        },
        detachWindow,
        reattachWindow,
        isDocPanelDetached,
        // docpanel concerns
        doc,
        setDoc,
        setHighlights,
        highlights,
        focused,
        setFocus,
        hovered,
        setHover,
        referenceText,
        setReferenceText,
        onSelectionAction,
        setReferenceTextEditCallback,
        selectionEnabled,
        setSelectionEnabled,
    }
}

export type Doc = {
    title: string;
    year: number;
    ticker: string;
    form: string;
    accessionNumber: string;
};


