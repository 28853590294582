import * as ScrollArea from "@radix-ui/react-scroll-area";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, theme } from "antd";
import { useDocLoader } from "./useDoc";
import { appContext } from "./useApp";
import { useContext, useEffect } from "react";
import { locateElem } from "src/utils/location";

export function DocPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context

  const hasDoc = Boolean(doc);
  return hasDoc ? (
    <DocViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}



// typescript still does not support Highlight api https://github.com/microsoft/TypeScript/issues/53003
declare class Highlight {
  constructor(...range: Range[]);
}
const MYCSS = CSS as any

function DocViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context
  if (!doc) throw new Error("Expected doc");

  // ------------------ highlight locations
  const highlights = context.highlights

  const figureRangeForLocator = (location: { startLocator: string | undefined, endLocator: string | undefined }) => {
    if (!location.startLocator || !location.endLocator) return
    const start = locateElem(location.startLocator)
    const finish = locateElem(location.endLocator)
    if (!start || !finish) return
    const range = new Range()
    if ("text" in start) {
      range.setStart(start.text, start.offset)
    } else {
      range.setStart(start.elem, 0)
    }
    if ("text" in finish) {
      range.setEnd(finish.text, finish.offset)
    } else {
      range.setEnd(finish.elem, 0)
    }
    return range
  }

  useEffect(() => {
    // remove previous highlights
    MYCSS.highlights.clear()

    if (highlights.length === 0) return
    const ranges = []
    for (const highlight of highlights) {
      const range = figureRangeForLocator(highlight)
      if (range) {
        ranges.push(range)
      }
    }
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.set("highlights", cssHighlight)

  }, [highlights])


  //------------- scroll to location
  const show = (location: string | undefined) => {
    if (!location) return
    const located = locateElem(location)
    if (located) {
      const elem = "text" in located ? located.text.parentElement : located.elem
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  }

  useEffect(() => {
    if (context.focused) {
      show(context.focused.locator)
    }
  }, [context.focused])



  useEffect(() => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return;
    $el
      .querySelectorAll(".hoverborder")
      .forEach((el) => el.classList.remove("hoverborder"));
    const locations = context.hovered
    if (!locations || locations.length === 0) return

    // super highlight

    const ranges = []
    for (const location of locations) {
      const range = figureRangeForLocator(location)
      if (range) {
        ranges.push(range)
      }
    }
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.delete("highlights_2")
    MYCSS.highlights.set("highlights_2", cssHighlight)

    const located = locateElem(locations.at(0)!.startLocator!)
    if (located) {
      const elem = "text" in located ? located.text.parentElement : located.elem
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }

  }, [context.hovered]);

  const viewer = useDocLoader(context.doc)


  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );

  return (
    <Flex
      vertical
      style={{
        height: "100%",
        border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
      }}
    >

      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: "0 0 15px 15px",
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            <div id="document-html"
              style={{
                padding: 20,
              }}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}