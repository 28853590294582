import { useCallback, useEffect, useState } from 'react';

interface RangeMenuProps {
    rangeRect?: DOMRect;
    onClose?: () => void;
    children: React.ReactNode;
}

// position children near a rangeRect
export function RangePositioner({ rangeRect, children, onClose }: RangeMenuProps) {
    const [position, setPosition] = useState<{ top: number; left: number; }>();
    const [cardRef, setCardRef] = useState<HTMLDivElement | null>(null);

    const measuredRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
          setCardRef(node);
        }
      }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!rangeRect) return;
            
            const target = event.target as Node;
            const menuElement = cardRef;
            if (menuElement && !menuElement.contains(target)) {
                // Notify parent to clear the range
                onClose?.();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [rangeRect, onClose, cardRef]);

    useEffect(() => {
        if (!rangeRect) {
            setPosition(undefined);
            return;
        }

        // Find the nearest positioned ancestor
        const findPositionedAncestor = (element: HTMLElement | null): HTMLElement | null => {
            if (!element || element === document.body) return null;
            const position = window.getComputedStyle(element).position;
            if (position !== 'static') return element;
            return findPositionedAncestor(element.parentElement);
        };


        // Get the positioned ancestor's coordinates
        const positionedAncestor = findPositionedAncestor(cardRef?.parentElement || null);
        const ancestorRect = positionedAncestor?.getBoundingClientRect() || { top: 0, left: 0 };

        // Calculate position relative to the positioned ancestor
        setPosition({
            top: rangeRect.bottom - ancestorRect.top,
            left: rangeRect.left - ancestorRect.left + (rangeRect.width / 2),
        });
     
    }, [rangeRect, cardRef]);

    if (!position) return null;

    return (
        <div 
        id="range-menu"
        ref={measuredRef}
        style={{
            position: 'absolute',
            top: position.top + 8, // Add some spacing from the selection
            left: position.left,
            transform: 'translateX(-50%)', // Center horizontally
            zIndex: 1000,
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        }}>
            {children}
        </div>

    );
}