import Split from "react-split";
import { PropsWithChildren, useState, useContext } from "react";
import { useDocumentTitle } from "@custom-react-hooks/use-document-title";
import { appContext } from "../useApp";
import { DocPanel } from "../DocPanel";
import { NavPanel } from "../NavPanel";
import { ReviewPanel } from "../ReviewPanel";
import { reviewsContext, useReviews } from "../useReviews";
import { useViewSelector, viewSelectorContext } from "../useViewSelector";
import { TablePanel } from "../TablePanel";
import { BlockOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { Button } from "antd";

export function ReviewerScreen() {
  const context = useContext(appContext);
  const title = `${process.env.REACT_APP_PROJECT_NAME} ${context.doc
    ? ` - ${context.doc.ticker} ${context.doc.accessionNumber}`
    : ""
    }`;
  useDocumentTitle(title);


  const reviewContext = useReviews(context.doc)

  const viewContext = useViewSelector()

  const hasDoc = context.doc;
  return (
    <appContext.Provider value={context}>
      {hasDoc
        ? <reviewsContext.Provider value={reviewContext}>
          <viewSelectorContext.Provider value={viewContext}>
            <ReviewToolScreen />
          </viewSelectorContext.Provider>
        </reviewsContext.Provider>
        : "no doc - pass in accession number and ticker"}
    </appContext.Provider>
  );
}

function ReviewToolScreen() {
  const viewContext = useContext(viewSelectorContext)
  const context = useContext(appContext)
  if (viewContext.view === "table") return <TableView />
  return context.isDocPanelDetached ? <DatapointsView /> : <CombinedDatapointsView />
}

function DatapointsView() {
  const [sizes, setSizes] = useState([0, 100]);
  const context = useContext(appContext)
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <NavPanel />
        </SplitPanel>
        <SplitPanel>
          <div style={{ position: "relative" }}>
            <Button style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }} icon={<MergeCellsOutlined />} onClick={() => {
              context.reattachWindow()
            }} />
          </div>
          <ReviewPanel />
        </SplitPanel>
      </Split>
    </div>
  );
}

function CombinedDatapointsView() {
  const [sizes, setSizes] = useState([0, 50, 50]);
  const context = useContext(appContext)
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <NavPanel />
        </SplitPanel>
        <SplitPanel>
          <div style={{ position: "relative" }}>
            <Button style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }} icon={<BlockOutlined />} onClick={() => {
              context.detachWindow()
            }} />
          </div>
          <ReviewPanel />
        </SplitPanel>
        <SplitPanel>

          {/* <ReferenceTextPanel referenceText={context.referenceText} /> */}
          <DocPanel {...context} />            
        </SplitPanel>
      </Split>
    </div>
  );
}


function TableView() {
  const [sizes, setSizes] = useState([0, 100]);
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <NavPanel />
        </SplitPanel>
        <SplitPanel>
          <TablePanel />
        </SplitPanel>
      </Split>
    </div>
  );
}


function SplitPanel(props: PropsWithChildren) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "visible",

      }}
    >
      {props.children}
    </div>
  );
}
