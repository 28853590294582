import * as ScrollArea from "@radix-ui/react-scroll-area";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, Menu, theme } from "antd";
import { useDocLoader } from "./useDoc";
import { useContext, useEffect } from "react";
import { UseDocPanel } from "./useApp";
import { figureRangeForLocator, locateElem } from "../../utils/location";
import { RangePositioner } from './RangePositioner';
import { useRangeSelection } from "./useRangeSelection";
import { SearchBox } from "./SearchBox";
import { SearchResult, useTextSearch } from "./useTextSearch";
import { loggerContext } from "./useLogger";
import { ReferenceTextPanel } from "./ReferenceTextPanel";


export function DocPanel(props: UseDocPanel) {
  const { token } = theme.useToken();
  const context = props
  const { doc } = context

  const hasDoc = Boolean(doc);
  return hasDoc ? (
    <DocViewerPanel {...context} />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}


// typescript still does not support Highlight api https://github.com/microsoft/TypeScript/issues/53003
declare class Highlight {
  constructor(...range: Range[]);
}
const MYCSS = CSS as any

function DocViewerPanel(props: UseDocPanel) {
  const { token } = theme.useToken();
  const context = props
  const { doc, highlights, hovered } = context
  if (!doc) throw new Error("Expected doc");

  //------------- func to scroll to location
  const show = (location: string | undefined) => {
    if (!location) return
    const located = locateElem(location)
    if (located) {
      const elem = "text" in located ? located.text.parentElement : located.elem
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  }

  //--------- func to highlight locations
  const highlightLocations = (locations: { startLocator: string | undefined, endLocator: string | undefined }[], kind: string) => {
    MYCSS.highlights.delete(kind)
    const ranges = []
    for (const location of locations) {
      const range = figureRangeForLocator(location)
      if (range) {
        ranges.push(range)
      }
    }
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.set(kind, cssHighlight)
  }

  useEffect(() => {
    if (context.focused) {
      show(context.focused.locator)
    }
  }, [context.focused])

  // ------------------ highlight locations
  useEffect(() => {
    highlightLocations(highlights, "highlights_faint")
  }, [highlights])

  // ---- sharper highlights of the 'hovered' location
  useEffect(() => {
    highlightLocations(hovered, "highlights_sharp")
  }, [hovered]);

  const viewer = useDocLoader(context.doc)

  //--- support user selecting a document fragment  
  const { rangeSelection, clearRangeSelection, captureRangeSelection } = useRangeSelection()
  const rangeRect = rangeSelection?.rangeRect
  const rangeText = rangeSelection?.text

  // --------- text search
  const textSearchContext = useTextSearch("document-html")
  const { current, results, searchTerm } = textSearchContext

  // logging -- connect search term to logger context
  const {updateLoggerContext} = useContext(loggerContext)
  useEffect(()=> {
    console.log("updating context from docpanel", searchTerm)
    updateLoggerContext("searchTerm", searchTerm)
  }, [searchTerm, updateLoggerContext])
  
  //------ highlight search results
  const highlightSearchResults = (results: SearchResult[], kind: string) => {
    MYCSS.highlights.delete(kind)
    const ranges = results.map(r => {
      const range = document.createRange()
      range.setStart(r.startNode, r.startOffset)
      range.setEnd(r.endNode, r.endOffset)
      return range
    })
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.set(kind, cssHighlight)
  }
  useEffect(() => {
    highlightSearchResults(results, "search_faint")
  }, [results])

  useEffect(() => {
    const currentItem = current !== undefined
      ? (results || [])[current]
      : undefined

    const toHighlight = currentItem ? [currentItem] : []
    highlightSearchResults(toHighlight, "search_sharp")
    if (currentItem) {
      currentItem.startNode.parentElement?.scrollIntoView({ block: "center" })
    }
  }, [results, current])

  //------ highlight current search result


  const menuItems = [{
    key: "apply",
    label: "Confirm selection",
    icon: <CheckCircleOutlined />
  }, {
    key: "cancel",
    label: "Cancel",
    icon: <CloseCircleOutlined />
  }]

  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );


  return (
    <Flex
      vertical
      style={{
        height: "100%",
        // border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        // boxShadow: token.boxShadowTertiary,
      }}
      gap={6}
    >
      <SearchBox {...textSearchContext} />
      <ReferenceTextPanel referenceText={context.referenceText} />


      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: "15px",
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            {rangeRect && rangeText && rangeText.length > 0 &&
              <RangePositioner rangeRect={rangeRect} onClose={() => clearRangeSelection()}>
                {context.selectionEnabled &&
                  <Menu
                    items={menuItems}
                    onClick={(e) => {
                      if (e.key === "apply") {
                        context.onSelectionAction("APPLY_SELECTION", rangeSelection)
                        clearRangeSelection()
                      } else if (e.key === "cancel") {
                        context.onSelectionAction("CANCEL_SELECTION")
                        clearRangeSelection()
                      }
                    }}
                  />
                }
              </RangePositioner>}
            <div id="document-html"
              style={{
                padding: 20,
              }}
              onMouseUp={captureRangeSelection}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}