
import { Review } from "../../../api-clients/def14a"
import { SOGTarget, SOGQualifying, SOGTimeFrame, SOGWithHolding, SogGuidelinesClicTable, SogClicTable } from "./sog"
import { CompensationPayout, CompensationTargets, LtiClicTable, Principle } from './lti'
import { createContext, useState } from 'react'
import { Button } from 'antd'
import { Popover } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { DynamicForm } from "../DynamicForm"
import { valueOf } from "../useReviews"
import { FiscalYear, NEO } from "./background"
export const DynamicCard = (props: { review: Review, canceling: boolean, onSubmit: (data: any) => void, onChange: (val:any)=> void, onDirty: (val: boolean) => void }) => {

    const ctx = useDatapointContext(props.review, props.canceling, props.onSubmit, props.onChange, props.onDirty)
    const { schema, value } = ctx
    if (!schema) {
        return <DefaultCard value={value} />
    }

    const key =  ctx.key
    if (!key) {
        return <DefaultCard value={value} />
    }
    const rendererLookup = {
        "def14a.background.fiscalYear": FiscalYear,
        "def14a.background.neos": NEO,
        "def14a.cda.lti.principle": Principle,
        "def14a.cda.lti.targets": CompensationTargets,
        "def14a.cda.lti.payouts": CompensationPayout,
        "def14a.cda.sog.target": SOGTarget,
        "def14a.cda.sog.qualifying": SOGQualifying,
        "def14a.cda.sog.timeFrame": SOGTimeFrame,
        "def14a.cda.sog.withHoldingRules": SOGWithHolding
    } as const

    const Renderer = rendererLookup[key as keyof typeof rendererLookup]
    if (!Renderer) {
        return <>
            missing renderer for {key}
            <DefaultCard value={value} />
        </>
    }
    return (
        <datapointContext.Provider value={ctx}>
            <DynamicForm>
                <Renderer/>
            </DynamicForm>
        </datapointContext.Provider>
    )
}

const useDatapointContext = (review: Review, canceling: boolean, onSubmit: (review: Review) => void, onChange: (val:any)=> void, onDirty: (val: boolean) => void) => {
    const definitions = review.reviewed.item.schema.definitions || {}
    const key = Object.keys(definitions)[0]
    const schema = definitions[key] as JSONSchema7
    const value = valueOf(review)
    return { schema, value, onSubmit, onDirty, key, canceling, onChange }
}

type UseDatapointContext = ReturnType<typeof useDatapointContext>
export const datapointContext = createContext(undefined as unknown as UseDatapointContext)



const DefaultCard = (props: { value: any }) => {
    return (
        <pre>
            {JSON.stringify(props.value, null, 2)}
        </pre>
    )
}

const tableLookup = {
    "def14a.cda.lti": LtiClicTable,
    "def14a.cda.sog": SogClicTable,
}

export const hasTableView = (subsection: string ) => {
    return subsection in tableLookup
}

export const ClicTable = (props: {reviews: Review<any>[]}) => {

    const subsections = props.reviews.map(r => r.datapointNamespace.split(".").slice(0, -1).join("."))
    const uniqueSubsections = [...new Set(subsections)]
    if (uniqueSubsections.length === 0) return <></>
    if (uniqueSubsections.length > 1) return <>multiple subsections not supported</>
    const subsection = uniqueSubsections[0]


    const Table = tableLookup[subsection as keyof typeof tableLookup]
    if (!Table) return <></>

    return (<Table {...props} />)
}

export const ClicTablePopover = (props: {reviews: Review<any>[]}) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
            <Popover open={open} onOpenChange={()=> setOpen(open => !!open)} content={<ClicTable {...props} />}>
                <Button type="link" onClick={() => setOpen(!open)}>table view (read only)</Button>
            </Popover>
    )
}

