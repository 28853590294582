import { CloseOutlined } from "@ant-design/icons"
import { Card } from "antd"
import { useEffect, useState } from "react"

export function ReferenceTextPanel(props: { referenceText: string | undefined }) {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    setVisible(true)
  }, [props.referenceText])
  const { referenceText } = props
  if (!referenceText || referenceText.length === 0) return null
  if (!visible) return null
  return <div style={{ position: "relative" }}>
    <CloseOutlined onClick={() => { setVisible(false) }} style={{color: "grey", position: "absolute", zIndex: 1001, top: 0, right: 0, padding: 6, fontSize: 10}}/>
    <div style={{ position: "absolute", zIndex: 1000, top: 0, left: -4 }}>
      <Card>
        {referenceText}
      </Card>
    </div>
  </div>
}
