import { ConsultantsUseCase } from "./consultants"
import { StiUseCase } from "./sti"
import { LtiUseCase } from "./lti"
import { Located, UseCase, UseCaseConnector } from "./types"
import { SalaryUseCase } from "./salary"
import { SOGUseCase } from "./sog"

export const SUPPORTED = ["salary", "STI", "LTI", "SOG", "consultants"] as const

export const RenderLocated = (
    props: {
        value: Located, 
        setFocus: (focus: { locator: string }) => void, 
        setHover: (hover: Located["location"][]) => void,
        children?: React.ReactNode
    }) => {
    const { value, setFocus, setHover, children } = props
    return <div className="hoverableCell"
        onMouseEnter={() => {
            value.location?.startLocator && setFocus({ locator: value.location?.startLocator });
            value.location && setHover([value.location])
        }}>
        {children || value.value}
    </div>
}

const map = {
    salary: SalaryUseCase,
    STI: StiUseCase,
    LTI: LtiUseCase,
    consultants: ConsultantsUseCase,
    SOG: SOGUseCase
} as Record<UseCase, UseCaseConnector<any>>


export function lookup(useCase: UseCase): UseCaseConnector<any> {
    const ret = map[useCase]
    if (!ret){
        throw new Error(`unknown use case: ${useCase}`)
    }
    return ret
}

export function renderApplicableTo(applicableTo: {officerName?: string, role?: string}) {
    if (!applicableTo || Object.keys(applicableTo).length == 0) return ""
    if (applicableTo.officerName) {
        return applicableTo.officerName
    }
    return applicableTo.role
}