import "./main.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App as AntApp, ConfigProvider } from "antd";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";
import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChatAppPage } from "./chat-app";
import { LogoutPage } from "./logout";
import { LoginPage } from "./login";
import { ProgressReportsPage } from "./progress-reports";
import { DevViewerPage } from "./dev-viewer";
import { Dev8k502Page } from "./dev-8k-502-viewer";
import { DevExplorerPage } from "./dev-explorer";
import { ReviewerPage } from "./reviewer";
import { DetachedDocPanelPage } from "./reviewer/detachedDocPanelPage";

export function Router() {
  const [queryClient] = useState(new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#57c", colorBgLayout: "#f4f4f5" },
        }}
      >
        <AntApp>
          <ClerkProvider
            publishableKey={`${process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}`}
            afterSignOutUrl="/logout"
          >
            <RouteDispatcher />
          </ClerkProvider>
        </AntApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

function RouteDispatcher() {
  const auth = useAuth();
  const [token, setToken] = useState<string>();

  const updateToken = useCallback(() => {
    auth.getToken().then((token) => {
      if (!token) return;
      setToken(token);
      localStorage.setItem("clerk-auth-token", token || "");
    });
  }, [auth]);

  useEffect(() => {
    if (auth.isLoaded && auth.isSignedIn && !token) {
      updateToken();
    }
  }, [auth, token, updateToken]);

  if (!auth.isSignedIn)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    );
  if (!token) return <div>...</div>;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/progress-reports" element={<ProgressReportsPage />} />
        <Route path="/explorer-tool" element={<DevExplorerPage />} />
        <Route path="/reviewer/detached" element={<DetachedDocPanelPage />} />
        <Route path="/reviewer" element={<ReviewerPage />} />
        <Route path="/viewer-tool" element={<DevViewerPage />} />
        <Route path="/8k-502-tool" element={<Dev8k502Page />} />
        <Route path="/" element={<ChatAppPage />} />
      </Routes>
    </BrowserRouter>
  );
}
